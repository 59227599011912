
import {
    computed, defineComponent, ref, watch
} from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { list as List } from '@/components/common/list';
import {
    initForm, initOptions, getFormRules, initList
} from '@/views/pm/old-community/temp-key/module/set/set';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import initCheck from '@/methods/relay-choose';
import { access } from '@/data';
import Notice from '@/util/notice.base';
import { tempKeyForPMApi } from '@/api';
import { format } from '@/methods/date';
import { byteLength } from 'byte-length';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        List
    },
    setup() {
        const breadHeaders = [{
            label: WordList.DarshbordTmp,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeys}`
        }, {
            label: WordList.ProperAllTextNew
        }];

        const {
            formRef,
            formData
        } = initForm();
        // 监听切换模式，加载不同rules
        const rules = ref(getFormRules(formData.value.SchedulerType));
        watch(computed(() => formData.value.SchedulerType), () => {
            rules.value = getFormRules(formData.value.SchedulerType);
        });
        const {
            repeatOptions,
            buildOptions,
            aptOptions,
            setRoomOption
        } = initOptions();

        const {
            headers,
            requestData,
            update
        } = initList();

        // 监听选择楼栋，动态改变apt
        watch(() => formData.value.Build, (buildId) => {
            formData.value.Room = '';
            setRoomOption(String(buildId));
            if (buildId !== '') {
                requestData.param = { Building: buildId };
            } else {
                requestData.param = {};
            }
            update.value += 1;
        });

        // relay
        const {
            allCheck, checkArray, dealList, choose, getCommit, changeAllCheck
        } = initCheck();

        // 提交
        const submit = () => {
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox(
                    'alert',
                    WordList.RuleMacChoose,
                    WordList.PersonuserInfo,
                    'info'
                )(() => false);
                return false;
            }
            formData.value.MAC = devices;

            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const param = { ...formData.value };

                    if (param.SchedulerType === access.weekly && typeof param.DateFlag !== 'string') {
                        param.DateFlag = param.DateFlag.join(';');
                    }

                    if (param.SchedulerType === access.neverEachCounts) {
                        param.BeginTime = format(new Date(), 'YYYY-MM-DD 00:00:00');
                        param.ExpireTime = format(param.ExpireTime, 'YYYY-MM-DD HH:mm:ss');
                    } else {
                        param.StartTime = format(param.StartTime, 'HH:mm:ss');
                        param.StopTime = format(param.StopTime, 'HH:mm:ss');
                    }
                    tempKeyForPMApi.addTempKey({ ...param }, () => {
                        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeys}`);
                    });
                }
            });
            return true;
        };

        return {
            formRef,
            formData,
            repeatOptions,
            buildOptions,
            aptOptions,
            setRoomOption,
            rules,
            breadHeaders,
            headers,
            requestData,
            update,
            dealList,
            changeAllCheck,
            checkArray,
            choose,
            allCheck,
            access,
            submit
        };
    }
});
