import { reactive, Ref, ref } from 'vue';
import { byteLength } from 'byte-length';
import { access } from '@/data';
import { checkEmail, checkNum } from '@/methods/rules/user';
import initBuildAptChoose from '@/methods/building-apt-choose';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import { AddFormDataType } from './all.type';

const initForm = () => {
    const formRef: Ref<any> = ref();
    const formData = ref<AddFormDataType>({
        Build: '',
        Room: '',
        Description: '',
        BeginTime: '',
        ExpireTime: '',
        StartTime: '',
        StopTime: '',
        Allow: '',
        MAC: [],
        IDNumber: '',
        Delivery: '',
        SchedulerType: '3',
        DateFlag: []
    });

    return {
        formRef,
        formData
    };
};

const initOptions = () => {
    const repeatOptions = [{
        label: WordList.ProperAllTextNever,
        value: '3'
    }, {
        label: WordList.ProperAllTextDaily,
        value: '1'
    }, {
        label: WordList.ProperAllTextWeekly,
        value: '2'
    }];

    const {
        buildOptions, aptOptions, setRoomOption
    } = initBuildAptChoose('all', 'aptAndName');

    return {
        repeatOptions,
        buildOptions,
        aptOptions,
        setRoomOption
    };
};

const checkTempKeyName: RuleMethod = (rule, value, callback) => {
    if (byteLength(value as string) > 63) {
        return callback(
            new Error(
                WordList.RuleLengthExceeds63.format(
                    WordList.TabelPersonUserInHtmlName,
                    WordList.TabelPersonUserInHtmlName
                )
            )
        );
    }
    return callback();
};

const getFormRules = (
    schedulerType: string
) => {
    const baseRules = {
        Description: [{
            required: true,
            message: WordList.RuleNameEmpty,
            trigger: 'blur'
        }, {
            validator: checkTempKeyName,
            trigger: 'blur'
        }],
        Allow: [{
            required: true,
            message: WordList.RuleAllowTimeEmpty,
            trigger: 'blur'
        }, {
            validator: checkNum,
            trigger: 'blur'
        }],
        Delivery: [
            {
                validator: checkEmail,
                trigger: 'blur'
            }
        ],
        MAC: [
            {
                required: true,
                message: WordList.RuleMacChoose,
                trigger: 'blur',
                type: 'array'
            }
        ]
    };
    const neverRules = {
        ExpireTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleExpireTimeEmpty,
            trigger: 'change'
        }]
    };
    const dailyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
    const weeklyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }],
        DateFlag: [{
            required: true,
            message: WordList.ProperAllTextDayRequired,
            trigger: 'blur'
        }]
    };
    if (schedulerType === access.never || schedulerType === access.neverEachCounts) {
        return {
            ...baseRules,
            ...neverRules
        };
    }
    if (schedulerType === access.daily) {
        return {
            ...baseRules,
            ...dailyRules
        };
    }
    // weekly
    return {
        ...baseRules,
        ...weeklyRules
    };
};

const headers: ListHeaderItem[] = [{
    name: 'UnitName',
    label: WordList.DeviceDetailDetailUnitName
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'Relay',
    label: WordList.ProperAllTextRelay,
    type: 'customize',
    headerType: 'customize'
}];

const initList = () => {
    const requestData: RequestData = reactive({
        url: 'v3/web/community/device/getAllPubDoor',
        param: {}
    });

    const update = ref(0);

    return {
        headers,
        requestData,
        update
    };
};

export default null;
export {
    initForm,
    getFormRules,
    initOptions,
    initList
};
